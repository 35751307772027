import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TelegramIcon from '@mui/icons-material/Telegram';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import GradingIcon from '@mui/icons-material/Grading';
import RuleIcon from '@mui/icons-material/Rule';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddLinkIcon from '@mui/icons-material/AddLink';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import BadgeIcon from '@mui/icons-material/Badge';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

export default function Online() {
  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Wallet`} />
                    </ListItemButton>
                </ListItem>
              </Link>


            

            {/* <Link  to='/' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText  primary={`P2P Trade`} />
                    </ListItemButton>
                </ListItem>
              </Link> */}

              <Link  to='/swap-coin' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <SwapHorizIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Swap`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/link-wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AddLinkIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Link Wallet`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/markets' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <SignalCellularAltIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Market`} />
                  </ListItemButton>
              </ListItem>
            </Link>


              <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <BadgeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`KYC Verification`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/transactions' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Transactions`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            {/* <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <RuleIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Rules`} />
                  </ListItemButton>
              </ListItem>
            </Link> */}
                     
          </List>
          <List>
            
            <Divider/>
            {/* <Link  to='https://tawk.to/chat/642872214247f20fefe94604/1gsuvrltc'  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Support`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <TelegramIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Telegram`} />
                </ListItemButton>
            </ListItem>
          </Link>

          {/* <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <EmailIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Email Contact`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <SettingsIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}